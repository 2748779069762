<template>
  <public-layout>
    <div class="container mx-auto p-6 content-center md:py-12 lg:px-12 md:w-1/2 xl:w-1/3">
      <div class="flex flex-col px-6 py-8 shadow-2xl rounded bg-white lg:px-12">
        <register-form />
      </div>
    </div>
  </public-layout>
</template>
<script>
import RegisterForm from '@/views/public/register/RegisterForm.vue';

export default {
  name: 'RegisterIndex',

  components: {
    PublicLayout: () => import('@/components/layouts/PublicLayout'),
    RegisterForm,
  }
}
</script>
